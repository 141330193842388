import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form } from "react-form";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Tabs } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import PageHeader from "../common/PageHeader";
import { WorkPlanHelpText, WorkPlanTab } from "../home/models";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const getHelpMessages = WorkPlanHelpText.selectAll();

const getTabs = WorkPlanTab.selectAll();

const styles = () => ({
    fields: {
        padding: "15px",
        border: "1px solid #eee",
        marginBottom: 30
    },
    error: {
        color: "red"
    }
});

class WorkPlanHelpMessages extends Component {
    toolbarConfig = {
        options: ["inline", "link", "list"],
        inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"]
        },
        link: {
            inDropdown: false,
            showOpenOptionOnHover: true,
            defaultTargetOption: "_self",
            options: ["link", "unlink"]
        },
        list: {
            inDropdown: false,
            options: ["ordered", "unordered"]
        }
    };

    constructor(props) {
        super(props);
        this.state = { selectedTab: "Introduction", subTab: null, formData: {}, errors: {}, updated: false };
    }

    componentDidMount() {
        document.title = "Configure Help Messages for Work Plans Screens - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
        this.setFormData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.messages !== this.props.messages) {
            const { updated } = this.state;
            if (updated) {
                return;
            }
            this.setFormData();
        }
    }

    setFormData = async () => {
        const { ormWorkPlanTabReload } = this.props;
        await ormWorkPlanTabReload();
        const { tabs } = this.props;
        const { selectedTab, subTab, errors } = this.state;

        await Object.keys(errors).forEach(field => (errors[field].required = false));
        await this.setState(prev => ({ ...prev, errors }));

        let fieldIdx = 0;
        let formData = {};
        await tabs.forEach(obj => {
            if (obj.tab_name === selectedTab) {
                if (obj.sub_tabs.length) {
                    const helpTexts = obj.sub_tabs.find(tab => tab.tab_name === subTab).tab_help_texts;
                    while (fieldIdx !== helpTexts.length) {
                        const found = helpTexts.find(msg => msg.field_index === fieldIdx); // eslint-disable-line no-loop-func
                        formData[`${found.label}-${fieldIdx}`] = this.getHTMLToDraft(found.text);
                        errors[`${found.label}-${fieldIdx}`] = { required: false };
                        fieldIdx++;
                    }
                } else {
                    if (obj.tab_help_texts.length) {
                        while (fieldIdx !== obj.tab_help_texts.length) {
                            const found = obj.tab_help_texts.find(msg => msg.field_index === fieldIdx); // eslint-disable-line no-loop-func
                            formData[`${found.label}-${fieldIdx}`] = this.getHTMLToDraft(found.text);
                            errors[`${found.label}-${fieldIdx}`] = { required: false };
                            fieldIdx++;
                        }
                    }
                }
            }
        });

        await this.setState(prev => ({ ...prev, formData, errors }));
    };

    switchTab = async (e, tabVal) => {
        await this.setState(prev => ({ ...prev, selectedTab: tabVal }));
        await this.setFormData();
    };

    switchSubTab = async (e, tabVal) => {
        await this.setState(prev => ({ ...prev, subTab: tabVal }));
        await this.setFormData();
    };

    changeEditorState = (contentState, field) => {
        let { formData, errors } = this.state;
        formData[field] = contentState;
        errors[field].required = !(draftToHtml(convertToRaw(formData[field].getCurrentContent())).length > 8);
        this.setState(prev => ({ ...prev, formData, errors }));
    };

    getHTMLToDraft = html => {
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        return EditorState.createWithContent(contentState);
    };

    isPCSubTabSelected = () => {
        const { subTab } = this.state;
        const pmSubTabs = ["Project Manager Info", "Organization Info", "Project Partner Info"];
        if (pmSubTabs.some(val => val === subTab)) {
            return subTab;
        }
        this.setState(prev => ({ ...prev, subTab: "Project Manager Info" }));
        return subTab;
    };

    isBudgetSubTabSelected = () => {
        const { subTab } = this.state;
        const budgetSubTabs = [
            "Personnel",
            "Services and Subawards",
            "Capital, Equipment, Tools, and Supplies",
            "Acquisition",
            "Travel and Conferences",
            "Printing and Publication",
            "Other",
            "Non-ENRTF Funds Contributed",
            "Summary"
        ];
        if (budgetSubTabs.some(val => val === subTab)) {
            return subTab;
        }
        this.setState(prev => ({ ...prev, subTab: "Personnel" }));
        return subTab;
    };

    isReviewSubTabSelected = () => {
        const { subTab } = this.state;
        const reviewSubTabs = [
            "Draft Proposal Review Comments",
            "My Staff Score",
            "Final Proposal Review Comments",
            "Staff Aggregate Scoring and Final Staff Score",
            "View Member Scoring Summary",
            "Staff Admin"
        ];
        if (reviewSubTabs.some(val => val === subTab)) {
            return subTab;
        }
        this.setState(prev => ({ ...prev, subTab: "Draft Proposal Review Comments" }));
        return subTab;
    };

    isAcqAndResSubtabSelected = () => {
        const { subTab } = this.state;
        const acqAndResSubTabs = [
            "Parcel List",
            "Fee Acquisition",
            "Conservation Easement Acquisition",
            "Easement Acquisition (Other)",
            "Restoration"
        ];
        if (acqAndResSubTabs.some(val => val === subTab)) {
            return subTab;
        }
        this.setState(prev => ({ ...prev, subTab: "Parcel List" }));
        return subTab;
    };

    ifErrors = () => {
        const { errors } = this.state;
        return Object.keys(errors).filter(field => errors[field].required).length > 0;
    };

    submitForm = () => {
        const { ormWorkPlanHelpTextUpdate, messages } = this.props;
        const { selectedTab, subTab, formData } = this.state;
        let updatedData = [];
        let updatedForm = {};
        Object.keys(formData).forEach(async (field, idx) => {
            let temp = {};
            let fieldID;

            try {
                fieldID = messages.find(
                    msg =>
                        msg.tab_name_label === selectedTab &&
                        msg.label === `${field.replace(`-${idx}`, "")}` &&
                        msg.field_index === idx
                ).id;
            } catch (e) {
                fieldID = messages.find(
                    msg =>
                        msg.tab_name_label === `Parent: ${selectedTab} => Child: ${subTab}` &&
                        msg.label === `${field.replace(`-${idx}`, "")}` &&
                        msg.field_index === idx
                ).id;
            }
            if (!(fieldID in updatedData.map(p => p.id))) {
                temp.id = fieldID;
                temp.text = draftToHtml(convertToRaw(formData[field].getCurrentContent()));
                updatedData.push(temp);
                updatedForm[field] = formData[field];

                this.setState(prev => ({ ...prev, updated: true }));
                await ormWorkPlanHelpTextUpdate(temp);
            }
        });
        this.setState(prev => ({ ...prev, formData: updatedForm }));
    };

    render() {
        const { tabs, classes } = this.props;
        const { selectedTab, subTab, formData, errors } = this.state;

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Configure Help Messages for Work Plans Screens" />

                    <Tabs
                        value={selectedTab}
                        onChange={this.switchTab}
                        variant="scrollable"
                        indicatorColor="primary"
                        scrollButtons="auto">
                        {tabs.map(tab => (
                            <Tab value={tab.tab_name} label={tab.tab_name} />
                        ))}
                    </Tabs>

                    {selectedTab === "Introduction" && (
                        <Grid item xs={12}>
                            <Typography variant="titleAction">Work Plan Introduction & Instructions</Typography>
                        </Grid>
                    )}
                    {selectedTab === "Project Collaborators" && (
                        <>
                            <Tabs value={this.isPCSubTabSelected()} onChange={this.switchSubTab} scrollButtons="auto">
                                {tabs
                                    .find(parent => parent.tab_name === selectedTab)
                                    .sub_tabs.map(tab => (
                                        <Tab value={tab.tab_name} label={tab.tab_name} />
                                    ))}
                            </Tabs>
                            <Grid item xs={12}>
                                <Typography variant="titleAction">
                                    {subTab === "Project Manager Info" && <>Project Manager Info</>}
                                    {subTab === "Organization Info" && <>Organization Contact Information</>}
                                    {subTab === "Project Partner Info" && <>Project Partners and Collaborators</>}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {selectedTab === "General Information" && (
                        <Grid item xs={12}>
                            <Typography variant="titleAction">Project Baseic Information</Typography>
                        </Grid>
                    )}
                    {selectedTab === "Narrative" && (
                        <Grid item xs={12}>
                            <Typography variant="titleAction">Project Description</Typography>
                        </Grid>
                    )}
                    {selectedTab === "Activities and Milestones" && (
                        <Grid item xs={12} lg={10} xl={7}>
                            <Typography variant="titleAction">Activities and Milestones</Typography>
                        </Grid>
                    )}
                    {selectedTab === "Budget" && (
                        <>
                            <Tabs
                                value={this.isBudgetSubTabSelected()}
                                onChange={this.switchSubTab}
                                variant="scrollable"
                                scrollButtons="auto">
                                {tabs
                                    .find(parent => parent.tab_name === selectedTab)
                                    .sub_tabs.map(tab => (
                                        <Tab value={tab.tab_name} label={tab.tab_name} />
                                    ))}
                            </Tabs>
                            <Grid item xs={12}>
                                <Typography variant="titleAction">
                                    {subTab === "Personnel" && <>Personnel Budget Details</>}
                                    {subTab === "Services and Subawards" && (
                                        <>Services and Subawards</>
                                    )}
                                    {subTab === "Capital, Equipment, Tools, and Supplies" && (
                                        <>Capital Expenditures, Equipment, Tools, and Supplies Details</>
                                    )}
                                    {subTab === "Acquisition" && <>Acquisition Details</>}
                                    {subTab === "Travel and Conferences" && <>Travel and Conference Details</>}
                                    {subTab === "Printing and Publication" && <>Printing and Publication Details</>}
                                    {subTab === "Other" && <>Other Expense Details</>}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    {selectedTab === "Acquisition and Restoration" && (
                        <>
                            <Tabs
                                value={this.isAcqAndResSubtabSelected()}
                                onChange={this.switchSubTab}
                                variant="scrollable"
                                scrollButtons="auto">
                                {tabs
                                    .find(parent => parent.tab_name === selectedTab)
                                    .sub_tabs.map(tab => (
                                        <Tab value={tab.tab_name} label={tab.tab_name} />
                                    ))}
                            </Tabs>
                        </>
                    )}
                    {!!Object.keys(formData).length && (
                        <Grid item xs={12}>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                defaultValues={formData}
                                onSubmit={this.submitForm}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            {Object.keys(formData).map((field, idx) => (
                                                <Grid item xs={12} key={`parent-${field}`} className={classes.fields}>
                                                    <label>
                                                        <h3>{field.replace(`-${idx}`, "")}</h3>
                                                    </label>
                                                    <Editor
                                                        toolbar={this.toolbarConfig}
                                                        editorState={formData[field]}
                                                        onEditorStateChange={editorState =>
                                                            this.changeEditorState(editorState, field)
                                                        }>
                                                        <textarea
                                                            disabled
                                                            value={draftToHtml(
                                                                convertToRaw(formData[field].getCurrentContent())
                                                            )}
                                                        />
                                                    </Editor>
                                                    {errors[field].required && (
                                                        <div className={classes.error}>*This field is required!</div>
                                                    )}
                                                </Grid>
                                            ))}

                                            <Grid item xs={6}>
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={this.ifErrors()}>
                                                    Save
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth component={Link} to="/adm/home">
                                                    Return to Admin Home
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

WorkPlanHelpMessages = connect(
    (state, ownProps) => ({
        authState: state.auth,
        messages: getHelpMessages(state, ownProps),
        tabs: getTabs(state, ownProps)
    }),
    {
        ...WorkPlanHelpText.actions,
        ...WorkPlanTab.actions
    }
)(WorkPlanHelpMessages);

export default withStyles(styles)(withRouter(WorkPlanHelpMessages));
