import React, { Component } from "react";
import DateFnsUtils from "@date-io/date-fns";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import ListAltIcon from "@material-ui/icons/ListAlt";

import { MAKE_OPTIONS } from "../../api/constants";
import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import CustomTableCell from "../common/TableCell";
import RadioGroup from "../common/RadioGroup";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import TextField from "../common/TextField";
import Select from "../common/Select";
import DatePicker from "../common/DatePicker";
import Snackbar from "../common/Snackbar";
import PageHeader from "../common/PageHeader";
import { createSelector, reloadListOfModels } from "../common/orm";

const getRFPYears = createSelector(schema => {
    return schema.SystemSetting.all()
        .orderBy("year", "desc")
        .toRefArray();
});

const getStaffUsers = createSelector(schema => {
    return schema.User.filter(x => x.is_active && x.role === "Staff").toRefArray();
});

const getProposals = createSelector(
    (state, ownProps) => (ownProps.location.serach !== "" ? parseInt(ownProps.location.search.split("=")[1]) : null),
    (schema, year) => {
        const setting = schema.SystemSetting.filter(x => x.year === year.toString()).first();

        if (setting && setting["evaluation2_open"]) {
            return schema.ProposalView.filter(
                x =>
                    x.proposal_unique_id.includes(year) &&
                    (x.selected_presentation === false ||
                        x.selected_presentation === true ||
                        x.recommended_for_funding !== null) &&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            )
                .orderBy("proposal_unique_id", "asc")
                .toModelArray()
                .map(view => {
                    var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                    var temp_staff =
                        org && org.staff_lead_id ? schema.User.filter(x => x.id === org.staff_lead_id).first() : null;
                    const org_staff_name = temp_staff ? temp_staff.first_name + " " + temp_staff.last_name : "";
                    const lead = schema.User.filter(x => x.id === view.staff_lead_id).first();
                    const temp = lead
                        ? {
                              first_name: lead.first_name,
                              last_name: lead.last_name
                          }
                        : {};

                    return {
                        ...view,
                        ...temp,
                        org_staff_name,
                        category: !view.final_funding_category
                            ? view.funding_category
                                ? view.funding_category +
                                  (view.secondary_funding_category ? " / " + view.secondary_funding_category : "")
                                : ""
                            : view.final_funding_category +
                              (view.final_secondary_funding_category
                                  ? " / " + view.final_secondary_funding_category
                                  : ""),
                        organization: org ? org.organization_name : "",
                        department: org ? org.department : ""
                    };
                });
        } else {
            return schema.ProposalView.filter(
                x =>
                    x.proposal_unique_id.includes(year) /*&&
                    (x.status === "Final Submitted" ||
                        x.status === "Revisions Submitted" ||
                        x.status === "Revisions Needed") */&&
                    x.secondary_status !== "Withdrawn" &&
                    x.secondary_status !== "Withdrawal Requested"
            )
                .orderBy("proposal_unique_id", "asc")
                .toModelArray()
                .map(view => {
                    var org = schema.Organization.filter(x => x.id === view.organization_id).first();
                    var temp_staff =
                        org && org.staff_lead_id ? schema.User.filter(x => x.id === org.staff_lead_id).first() : null;
                    const org_staff_name = temp_staff ? temp_staff.first_name + " " + temp_staff.last_name : "";
                    const lead = schema.User.filter(x => x.id === view.staff_lead_id).first();
                    const temp = lead
                        ? {
                              first_name: lead.first_name,
                              last_name: lead.last_name
                          }
                        : {};

                    return {
                        ...view,
                        ...temp,
                        category: !view.final_funding_category
                            ? view.funding_category
                                ? view.funding_category +
                                  (view.secondary_funding_category ? " / " + view.secondary_funding_category : "")
                                : ""
                            : view.final_funding_category +
                              (view.final_secondary_funding_category
                                  ? " / " + view.final_secondary_funding_category
                                  : ""),
                        org_staff_name,
                        proposal_staff: lead && lead.first_name ? lead.first_name + " " + lead.last_name : "",
                        organization: org ? org.organization_name : "",
                        department: org ? org.department : ""
                    };
                });
        }
    }
);

const getWorkPlans = createSelector(
    (state, ownProps) =>
        ownProps.location.serach !== "" ? parseInt(ownProps.location.search.split("=")[1]).toString() : null,
    (schema, year) => {
        return schema.ProposalView.all()
            .filter(
                x =>
                    x.ml_year === year &&
                    (x.primary_phase === "Draft Work Plan" || x.primary_phase === "Final Work Plan")
            )
            .orderBy("proposal_unique_id", "asc")
            .toModelArray()
            .flatMap(view => {
                var plan, plan2;
                plan = schema.DraftWorkPlanView.filter(x => x.proposal_record_id === view.id).first();
                plan2 = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === view.id).first();

                
                if (view.primary_phase.includes("Work Plan") && !plan && !plan2)
                    return []


                var final_category, org_staff_name;
                var temp = {};
                if (plan2) {
                    plan = plan2;
                }
                if (plan) {
                    final_category =
                        plan.funding_category +
                        (plan.secondary_funding_category && plan.funding_category === "Small Projects"
                            ? " / " + plan.secondary_funding_category
                            : "");

                    schema.FundingCategory.filter(
                        x => x.category_name === plan.funding_category && x.year_label === plan.ml_year
                    ).first();

                    var org = schema.Organization.filter(x => x.id === plan.organization_id).first();
                    var temp_staff =
                        org && org.staff_lead_id ? schema.User.filter(x => x.id === org.staff_lead_id).first() : null;
                    org_staff_name = temp_staff ? temp_staff.first_name + " " + temp_staff.last_name : "";
                    const lead = schema.User.filter(x => x.id === view.staff_lead_id).first();
                    temp = lead
                        ? {
                            first_name: lead.first_name,
                            last_name: lead.last_name
                        }
                        : {};
                }

                return {
                    id: view.id,
                    user_id: view.user_id,
                    ml_year: view.ml_year,
                    proposal_unique_id: view.proposal_unique_id,
                    recommended_amount: view.recommended_amount,
                    appropriation_amount: view.appropriation_amount,
                    name: plan ? plan.name : view.name,
                    pm_first_name: plan ? plan.pm_first_name : view.pm_first_name,
                    pm_last_name: plan ? plan.pm_last_name : view.pm_last_name,
                    category: final_category,
                    funding_total: plan ? plan.funding_total : "",
                    status: view.status,
                    secondary_status: view.secondary_status,
                    full_name: plan ? plan.pm_first_name + " " + plan.pm_last_name : view.pm_first_name + " " + view.pm_last_name,
                    organization_name: org ? org.organization_name : "",

                    include_research: plan ? plan.include_research : "",
                    peer_review: view.peer_review,
                    is_research_project: view.is_research_project,
                    peer_waived: view.peer_waived,
                    explanation_waiver: view.explanation_waiver,
                    lock_research_addendum: view.lock_research_addendum,
                    workplan_approved: view.workplan_approved,
                    workplan_date: view.workplan_date,
                    workplan_approval_notes: view.workplan_approval_notes,
                    consent: view.consent,
                    governor_date: view.governor_date,
                    through: view.through,
                    agency: view.agency,
                    fiscal_year_a: view.fiscal_year_a,
                    fiscal_year_a_amount: view.fiscal_year_a_amount,
                    fiscal_year_b: view.fiscal_year_b,
                    fiscal_year_b_amount: view.fiscal_year_b_amount,
                    fiscal_year_c: view.fiscal_year_c,
                    fiscal_year_c_amount: view.fiscal_year_c_amount,
                    fiscal_year_d: view.fiscal_year_d,
                    fiscal_year_d_amount: view.fiscal_year_d_amount,
                    fiscal_year_notes: view.fiscal_year_notes,
                    enrtf: view.enrtf,
                    enrtf_amount: view.enrtf_amount,
                    frf: view.frf,
                    frf_amount: view.frf_amount,
                    oocm: view.oocm,
                    oocm_amount: view.oocm_amount,
                    glpa: view.glpa,
                    glpa_amount: view.glpa_amount,
                    source_of_funds_notes: view.source_of_funds_notes,
                    ag_forestry_mining: view.ag_forestry_mining,
                    biological_diversity: view.biological_diversity,
                    ed_outreach: view.ed_outreach,
                    historic_preservation: view.historic_preservation,
                    invasive_species: view.invasive_species,
                    nat_areas_habitat: view.nat_areas_habitat,
                    nr_info_planning: view.nr_info_planning,
                    recreation: view.recreation,
                    renewable_energy: view.renewable_energy,
                    water_resources: view.water_resources,
                    admin: view.admin,
                    link_to_subd: view.link_to_subd,
                    enrtf_custom_id: view.enrtf_custom_id,
                    special_session: view.special_session,
                    article: view.article,
                    citation_ml_year: view.citation_ml_year,
                    chapter: view.chapter,
                    section: view.section,
                    subdivision: view.subdivision,
                    app_lanuage: view.app_lanuage,
                    additional_app_lanuage: view.additional_app_lanuage,
                    additional_citation: view.additional_citation,
                    legislative_notes: view.legislative_notes,
                    app_end_date: view.app_end_date,
                    legislative_date: view.legislative_date,
                    organization: org ? org.organization_name : "",
                    department: org ? org.department : "",
                    org_staff_name: org_staff_name ? org_staff_name : "",
                    ...temp
                };
            });
    }
);

const getLegislativeAmendments = createSelector(
    (state, ownProps) =>
        ownProps.location.serach !== "" ? parseInt(ownProps.location.search.split("=")[1]).toString() : null,
        (schema, year) => {
            return schema.LegislativeAmendment.filter(la => {
                var plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === la.proposal_id).first();
                return plan.ml_year === year;
            })
                .orderBy("proposal", "desc")
                .toModelArray()
                .map(la => {
                    var plan = schema.FinalWorkPlanView.filter(x => x.proposal_record_id === la.proposal_id).first();
                    var view = schema.ProposalView.filter(x => x.id === la.proposal_id).first();
    
                    var final_category = plan.funding_category +
                        (plan.secondary_funding_category && plan.funding_category === "Small Projects" ? " / " + plan.secondary_funding_category : "");
    
      
                    var org = schema.Organization.filter(x => x.id === plan.organization_id).first();
                    var temp_staff = org && org.staff_lead_id ? schema.User.filter(x => x.id === org.staff_lead_id).first() : null;
                    const org_staff_name = temp_staff ? temp_staff.first_name + " " + temp_staff.last_name : "";
                    const lead = schema.User.filter(x => x.id === view.staff_lead_id).first();
                    const temp = lead
                        ? {
                              first_name: lead.first_name,
                              last_name: lead.last_name
                          }
                        : {};
    
                    return {
                        id: view.id,
                        user_id: view.user_id,
                        ml_year: view.ml_year,
                        proposal_unique_id: view.proposal_unique_id,
                        recommended_amount: view.recommended_amount,
                        appropriation_amount: view.appropriation_amount,
                        name: plan.name,
                        pm_first_name: plan.pm_first_name,
                        pm_last_name: plan.pm_last_name,
                        category: final_category,
                        funding_total: plan.funding_total,
                        status: view.status,
                        secondary_status: view.secondary_status,
                        full_name: plan.pm_first_name + " " + plan.pm_last_name,
                        organization_name: org ? org.organization_name : "",
    
                        include_research: plan.include_research,
                        peer_review: view.peer_review,
                        is_research_project: view.is_research_project,
                        peer_waived: view.peer_waived,
                        explanation_waiver: view.explanation_waiver,
                        lock_research_addendum: view.lock_research_addendum,
                        workplan_approved: view.workplan_approved,
                        workplan_date: view.workplan_date,
                        workplan_approval_notes: view.workplan_approval_notes,
                        consent: view.consent,
                        governor_date: view.governor_date,
                        through: view.through,
                        agency: view.agency,
                        special_session: view.special_session,
                        article: view.article,
                        citation_ml_year: view.citation_ml_year,
                        chapter: view.chapter,
                        section: view.section,
                        subdivision: view.subdivision,
                        app_lanuage: view.app_lanuage,
                        fiscal_year_a: view.fiscal_year_a,
                        fiscal_year_a_amount: view.fiscal_year_a_amount,
                        fiscal_year_b: view.fiscal_year_b,
                        fiscal_year_b_amount: view.fiscal_year_b_amount,
                        fiscal_year_c: view.fiscal_year_c,
                        fiscal_year_c_amount: view.fiscal_year_c_amount,
                        fiscal_year_d: view.fiscal_year_d,
                        fiscal_year_d_amount: view.fiscal_year_d_amount,
                        fiscal_year_notes: view.fiscal_year_notes,
                        enrtf: view.enrtf,
                        enrtf_amount: view.enrtf_amount,
                        frf: view.frf,
                        frf_amount: view.frf_amount,
                        oocm: view.oocm,
                        oocm_amount: view.oocm_amount,
                        glpa: view.glpa,
                        glpa_amount: view.glpa_amount,
                        source_of_funds_notes: view.source_of_funds_notes,
                        ag_forestry_mining: view.ag_forestry_mining,
                        biological_diversity: view.biological_diversity,
                        ed_outreach: view.ed_outreach,
                        historic_preservation: view.historic_preservation,
                        invasive_species: view.invasive_species,
                        nat_areas_habitat: view.nat_areas_habitat,
                        nr_info_planning: view.nr_info_planning,
                        recreation: view.recreation,
                        renewable_energy: view.renewable_energy,
                        water_resources: view.water_resources,
                        admin: view.admin,
                        link_to_subd: view.link_to_subd,
                        enrtf_custom_id: view.enrtf_custom_id,
                        additional_app_lanuage: view.additional_app_lanuage,
                        additional_citation: view.additional_citation,
                        legislative_notes: view.legislative_notes,
                        app_end_date: view.app_end_date,
                        legislative_date: view.legislative_date,
                        organization: org ? org.organization_name : "",
                        department: org ? org.department : "",
                        org_staff_name,
                        ...temp,
                        ...la
                    };
                });
        }
    );

const styles = theme => ({
    table: {
        width: "100%",
        "& tbody tr:nth-child(even)": {
            backgroundColor: "#eee"
        },
        marginBottom: 20,
        backgroundColor: "#fff"
    },
    centerAlign: {
        textAlign: "center"
    },
    expansionSummaryRoot: {
        backgroundColor: "#eee",
        minHeight: 64,
        borderBottom: "1px dotted",
        "& div:first-child": {
            margin: 0,
            marginBottom: -24
        }
    },
    expansionIcon: {
        marginTop: 6,
        marginBottom: -6,
        marginRight: 8
    },
    tableSelect: {
        width: 150,
        marginTop: "-22px !important",
        paddingLeft: 4,
        paddingRight: 4
    },
    tableSelect1: {
        width: 200,
        marginTop: "-22px !important",
        paddingLeft: 4,
        paddingRight: 4
    },
    textField: {
        minWidth: "100px"
    },
    cellWidth_1: {
        minWidth: "80px !important"
    },
    cellWidth_115: {
        minWidth: "115px !important"
    },
    cellWidth_2: {
        minWidth: "200px !important"
    },
    cellWidth_35: {
        minWidth: "350px !important"
    },
    cellWidth_50: {
        minWidth: "500px !important"
    },
    contingencies: {
        width: 250
    }
});

const commonColumnDataNoOrgLeadOrSubd = [
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "first_name", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true }
];

const commonColumnData = [
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "org_staff_name", numeric: false, label: "Organization Current Staff Lead", allowSort: true },
    { id: "first_name", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true }
];

const commonColumnDataWorkPlan = [
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "ml_id", numeric: false, label: "M.L. Subd", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "first_name", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Appropriation Amount", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true }
];

const commonColumnDataWorkPlan2 = [
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "ml_id", numeric: false, label: "M.L. Subd", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "first_name", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Funding", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true }
];

const commonColumnDataWorkPlan3 = [
    { id: "proposal_unique_id", numeric: false, label: "Proposal ID", allowSort: true },
    { id: "ml_id", numeric: false, label: "M.L. Subd", allowSort: true },
    { id: "name", numeric: false, label: "Project Title", allowSort: true },
    { id: "organization", numeric: false, label: "Organization", allowSort: true },
    { id: "first_name", numeric: false, label: "Staff Lead", allowSort: true },
    { id: "category", numeric: false, label: "Category", allowSort: true },
    { id: "pm_last_name", numeric: false, label: "Project Manager", allowSort: true },
    { id: "funding_total", numeric: true, label: "Recommended Amount", allowSort: true },
    { id: "status", numeric: false, label: "Proposal Status", allowSort: true }
];

const staffData = [{ id: "staff_lead_id", numeric: false, label: "Staff Lead", aLabel: "stafflead" }];

const selectedData = [
    {
        id: "selected_presentation",
        numeric: false,
        label: "Selected For Presentation",
        allowSort: true,
        bool: true,
        aLabel: "selectedforpresentation"
    }
];

const recommendedData = [
    {
        id: "selected_presentation",
        numeric: false,
        label: "Selected For Presentation",
        allowSort: true,
        bool: true,
        aLabel: "selectedforpresentation"
    },
    {
        id: "recommended_for_funding",
        numeric: false,
        label: "Recommended For Funding",
        allowSort: true,
        bool: true,
        aLabel: "recommendedforfunding"
    },
    {
        id: "recommended_amount",
        numeric: true,
        label: "Recommended Amount",
        allowSort: true,
        aLabel: "recommendedamount"
    },
    { id: "contingencies", numeric: false, label: "Contingencies", allowSort: true, aLabel: "contingencies" }
];

const lAmendmentData = [
    {
        id: "date_submitted",
        date: true,
        label: "Date Requested",
        allowSort: true,
        aLabel: "date_submitted"
    },
    { 
        id: "why", 
        label: "Reason for Request", 
        allowSort: true, 
        aLabel: "why" 
    },
    {
        id: "timeline",
        date: true,
        label: "New Completion Date",
        allowSort: true,
        aLabel: "timeline"
    },
    {
        id: "passed_legislature",
        label: "Legislative Amendment Passed By The Legislature",
        allowSort: true,
        bool: true,
        aLabel: "passed_legislature"
    },
    {
        id: "passed_governor",
        label: "Legislative Amendment Governor Approved",
        allowSort: true,
        bool: true,
        aLabel: "passed_governor"
    },
    { 
        id: "governor_approved_date", 
        label: "Governor Approved Date", 
        allowSort: false, 
        aLabel: "governor_approved_date" 
    },
    { 
        id: "amendment_notes", 
        label: "Legislative Amendment Notes", 
        allowSort: true, 
        aLabel: "amendment_notes" 
    },
];

const researchData = [
    {
        id: "include_research",
        numeric: false,
        label: "Includes Research",
        allowSort: true,
        bool: true,
        aLabel: "includeresearch"
    },
    {
        id: "is_research_project",
        numeric: false,
        label: "Is Research Project?",
        allowSort: true,
        bool: true,
        aLabel: "isresearch"
    },
    {
        id: "peer_review",
        numeric: false,
        label: "Peer Review Needed",
        allowSort: true,
        bool: true,
        aLabel: "peerreviewneeded"
    },
    {
        id: "peer_waived",
        numeric: false,
        label: "Peer Review Waived",
        allowSort: true,
        bool: true,
        aLabel: "peerreviewwaived"
    },
    {
        id: "explanation_waiver",
        numeric: false,
        label: "Explanation for Waiver",
        allowSort: true,
        aLabel: "explanationwaiver"
    },
    {
        id: "lock_research_addendum",
        numeric: false,
        label: "Peer Review Completed",
        allowSort: true,
        bool: true,
        aLabel: "peerreviewcompleted"
    }
];

const governorData = [
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    { id: "new_status", numeric: false, label: "Governor Action", allowSort: true, aLabel: "governoraction" },
    {
        id: "governor_date",
        numeric: false,
        label: "Date Of Governor Action",
        date: true,
        aLabel: "governordate"
    }
];

const workPlanData = [
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    {
        id: "workplan_approved",
        numeric: false,
        label: "Work Plan Approved",
        allowSort: true,
        bool: true,
        aLabel: "workplanapproved"
    },
    {
        id: "workplan_date",
        numeric: false,
        label: "Date Of Work Plan Approval",
        allowSort: true,
        date: true,
        aLabel: "dateofworkplanapproval"
    },
    {
        id: "consent",
        numeric: false,
        label: "Consent or Non-Consent",
        allowSort: true,
        bool: true,
        aLabel: "consent"
    },
    {
        id: "workplan_approval_notes",
        numeric: false,
        label: "Work Plan Approval Notes",
        allowSort: true,
        aLabel: "workplanapprovalnotes"
    },
];

const adminsteringData = [
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    { id: "through", numeric: false, label: "Direct or Pass Through", allowSort: true, aLabel: "through" },
    { id: "agency", numeric: false, label: "Administering Agency", allowSort: true, aLabel: "agency" }
];

const fiscalYearData = [
    { id: "fiscal_year_a", numeric: false, label: "Fiscal Year A", allowSort: true },
    { id: "fiscal_year_a_amount", numeric: true, label: "Fiscal Year A Amount", allowSort: true },
    { id: "fiscal_year_b", numeric: false, label: "Fiscal Year B", allowSort: true },
    { id: "fiscal_year_b_amount", numeric: true, label: "Fiscal Year B Amount", allowSort: true },
    { id: "fiscal_year_c", numeric: false, label: "Fiscal Year C", allowSort: true },
    { id: "fiscal_year_c_amount", numeric: true, label: "Fiscal Year C Amount", allowSort: true },
    { id: "fiscal_year_d", numeric: false, label: "Fiscal Year D", allowSort: true },
    { id: "fiscal_year_d_amount", numeric: true, label: "Fiscal Year D Amount", allowSort: true },
    { id: "fiscal_year_notes", numeric: false, label: "Fiscal Year Notes", allowSort: true },
];

const sourceFundsData = [
    { id: "enrtf", bool: true, label: "Environment and Natural Resources Trust Fund", allowSort: true },
    { id: "enrtf_amount", numeric: true, label: "ENRTF Amount", allowSort: true },
    { id: "frf", bool: true, label: "Future Resources Fund", allowSort: true },
    { id: "frf_amount", numeric: true, label: "FRF Amount", allowSort: true },
    { id: "oocm", bool: true, label: "Oil Overcharge Money", allowSort: true },
    { id: "oocm_amount", numeric: true, label: "OOCM Amount", allowSort: true },
    { id: "glpa", bool: true, label: "Great Lakes Protection Account", allowSort: true },
    { id: "glpa_amount", numeric: true, label: "GLPA Amount", allowSort: true },
    { id: "source_of_funds_notes", numeric: false, label: "Source of Funds Notes", allowSort: true },
];

const legacyData = [
    { id: "ag_forestry_mining", bool: true, label: "Agriculture/Forestry/Mining", allowSort: true },
    { id: "biological_diversity", bool: true, label: "Biological Diversity", allowSort: true },
    { id: "ed_outreach", bool: true, label: "Educational Outreach", allowSort: true },
    { id: "historic_preservation", bool: true, label: "Historic Preservation", allowSort: true },
    { id: "invasive_species", bool: true, label: "Invasive Species", allowSort: true },
    { id: "nat_areas_habitat", bool: true, label: "Natural Areas and Habitat", allowSort: true },
    { id: "nr_info_planning", bool: true, label: "Natural Resource Information and Planning", allowSort: true },
    { id: "recreation", bool: true, label: "Recreation", allowSort: true },
    { id: "renewable_energy", bool: true, label: "Renewable Energy", allowSort: true },
    { id: "water_resources", bool: true, label: "Water Resources", allowSort: true },
    { id: "admin", bool: true, label: "Administration", allowSort: true },
    { id: "link_to_subd", numeric: false, label: "Link to Project", allowSort: true },
    { id: "enrtf_custom_id", numeric: false, label: "ENRTF Custom ID #", allowSort: true },
];

const legalData = [
    { id: "secondary_status", numeric: false, label: "LCCMR/Legislative Status", allowSort: true },
    {
        id: "appropriation_amount",
        numeric: true,
        label: "Appropriation Amount",
        allowSort: true,
        aLabel: "appropriationamount"
    },
    {
        id: "app_end_date",
        numeric: false,
        label: "Appropriation End Date",
        date: true,
        allowSort: true,
        aLabel: "appenddate"
    },
    { id: "citation_ml_year", numeric: false, label: "M.L Year", allowSort: true, aLabel: "m.l_year" },
    { id: "special_session", numeric: false, label: "Special Session", allowSort: true, aLabel: "specialsession" },
    { id: "chapter", numeric: false, label: "Chapter", allowSort: true, aLabel: "chapter" },
    { id: "article", numeric: false, label: "Article", allowSort: true, aLabel: "article" },
    { id: "section", numeric: false, label: "Section", allowSort: true, aLabel: "section" },
    { id: "subdivision", numeric: false, label: "Subdivision", allowSort: true, aLabel: "subdivion" },
    { id: "additional_citation", numeric: false, label: "Additional Legal Citation", allowSort: true, aLabel: "additional_c" },
    { id: "app_lanuage", numeric: false, label: "Approriation Language", allowSort: true, aLabel: "applanguage" },
    { id: "additional_app_lanuage", numeric: false, label: "Additional Approriation Language", allowSort: true, aLabel: "additional_a" },
    { id: "legislative_notes", numeric: false, label: "Legislative Notes", allowSort: true, aLabel: "notes" },
];

const legistLativeData = [
    { id: "new_status", numeric: false, label: "Legislative Action", allowSort: true, aLabel: "legislativeaction" },
    {
        id: "legislative_date",
        numeric: false,
        label: "Date of Legislative Action",
        date: true,
        aLabel: "legislativedate"
    }
];

const governerOptions = ["Governor Approved", "Governor Approved As Adjusted", "Governor Veto"];

const legisLativeActionOptions = [
    "Pending Action By The Legislature",
    "Passed By Legislature",
    "Not Passed By Legislature",
    "Adjusted And Passed By Legislature"
];

const proposalOptions = [
    "Staff Lead Assignment",
    "Selected for Presentation",
    "Recommended, Allocations, And Contingencies"
];
const options = [
    "Staff Lead Assignment",
    "Selected for Presentation",
    "Recommended, Allocations, And Contingencies",
    "Research and Peer Review",
    "Legislative Action",
    "Governor Action",
    "Legal Citation and Appropriation Language",
    "Administering Agency",
    "Work Plan Approval",
    "Legislative Amendments",
    "Fiscal Year",
    "Source of Funds",
    "All Subject and Legacy Data"
];
const optionsKeys = [
    "staff_lead_id",
    "selected_presentation",
    "recommended_for_funding",
    "recommended_amount",
    "contingencies",
    "is_research_project",
    "peer_review",
    "peer_waived",
    "explanation_waiver",
    "lock_research_addendum",
    "workplan_approved",
    "workplan_date",
    "workplan_approval_notes",
    "consent",
    "governor_action",
    "governor_date",
    "through",
    "agency",
    "appropriation_amount",
    "special_session",
    "article",
    "citation_ml_year",
    "chapter",
    "section",
    "subdivision",
    "app_lanuage",
    "additional_citation",
    "additional_app_lanuage",
    "legislative_notes",
    "legislative_action",
    "legislative_date",
    "new_status"
];

class BatchProposal extends Component {
    state = {
        activeRFP: null,
        dont: false,
        isSuccess: true,
        info: null,
        loading: false,
        page: 0,
        rowsPerPage: 50,
        order: "desc",
        orderBy: "",
        snackbarOpen: false,
        statusFilter: "All",
        is_loading: false
    };
    Formref = React.createRef();

    componentDidMount() {
        document.title = "Batch Proposal Update - LCCMR Proposal and Grant Management System";
        const { authState, history } = this.props;
        if (authState && authState.user && authState.user.role !== "Staff") {
            history.push("/dashboard");
        }
    }

    componentDidUpdate = e => {
        //allows changing of form values to different RFP year
        if (this.state.dont) {
            this.setState({ dont: false });
        }

        if (this.state.loading && this.props.syncState.ready) {
            this.setState({ loading: false, dont: true, snackbarOpen: true, isSuccess: true });
        }
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value, page: 0 });
    };

    handleYear = e => {
        const { history, rfpYears } = this.props;
        const { info } = this.state;
        const index = rfpYears.findIndex(x => x.year === e);
        const active = index > -1 ? rfpYears[index] : {};
        this.setState({ dont: true, activeRFP: active });

        if (rfpYears[index] && !rfpYears[index].draft_created && !proposalOptions.includes(info)) {
            this.setState({ info: null });
            if (this.selectApi) {
                this.selectApi.setValue("info", null);
            }
        }

        history.push({ search: `year=${e.toString()}` });
    };

    handleInfo = e => {
        var isProposal = true;
        if (
            [
                "Governor Action",
                "Work Plan Approval",
                "Administering Agency",
                "Legislative Amendments",
                "Legal Citation and Appropriation Language",
                "Legislative Action",
                "Research and Peer Review",    
                "Fiscal Year",
                "Source of Funds",
                "All Subject and Legacy Data"
            ].includes(e)
        ) {
            isProposal = false;
        }
        this.setState({ dont: true, info: e, isProposal });
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    handleRequestSort = (event, property) => {
        const { isProposal } = this.state;
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        var colData = this.getColumnData();

        var key = isProposal ? "proposals" : "workplans";

        if (optionsKeys.includes(property)) {
            var test = this.Formref.current.store.getState();

            var arr = [];

            Object.keys(test.values).forEach(x => {
                arr.push(test.values[x]);
            });

            var value = arr.sort(function(a, b) {
                var numeric = colData.find(cD => cD.id === orderBy).numeric;
                var bool = colData.find(cD => cD.id === orderBy).bool;

                if (bool) {
                    if (order === "desc") return (b[orderBy] || false) - (a[orderBy] || false);
                    else return (a[orderBy] || false) - (b[orderBy] || false);
                } else if (numeric) {
                    if (a[orderBy] === b[orderBy]) return 0;
                    else if (a[orderBy] === null || typeof a[orderBy] === "undefined") return 1;
                    else if (b[orderBy] === null || typeof b[orderBy] === "undefined") return -1;
                    if (order === "desc") return parseInt(b[orderBy]) - parseInt(a[orderBy]);
                    return parseInt(a[orderBy]) - parseInt(b[orderBy]);
                } else {
                    if (order === "desc")
                        return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                    else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
                }
            });

            this.props[key].sort(function(a, b) {
                return value.findIndex(x => x.id === a.id) - value.findIndex(z => z.id === b.id);
            });
        } else {
            this.props[key].sort(function(a, b) {
                var date = colData.find(cD => cD.id === orderBy).date;
                var numeric = colData.find(cD => cD.id === orderBy).numeric;
                var bool = colData.find(cD => cD.id === orderBy).bool;
                if (date) {
                    if (order === "desc") return new Date(b[orderBy]) < new Date(a[orderBy]) ? -1 : 1;
                    else return new Date(a[orderBy]) < new Date(b[orderBy]) ? -1 : 1;
                } else if (bool) {
                    if (order === "desc") return (b[orderBy] || false) - (a[orderBy] || false);
                    else return (a[orderBy] || false) - (b[orderBy] || false);
                } else if (numeric) {
                    if (order === "desc") return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
                    else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
                } else {
                    if (order === "desc")
                        return (b[orderBy] || "").toUpperCase() < (a[orderBy] || "").toUpperCase() ? -1 : 1;
                    else return (a[orderBy] || "").toUpperCase() < (b[orderBy] || "").toUpperCase() ? -1 : 1;
                }
            });
        }
        this.setState({ order, orderBy });
    };

    errorValidator = values => {
        const { info } = this.state;
        if (info === "Legislative Amendments") {
            var returnobj = {};
            var showAlert = false;
            for (var row in values) {
                returnobj[values[row].id] = {
                    'governor_approved_date': null
                }
                if (values[row].passed_governor === true || values[row].passed_governor === "true") {
                    if (!values[row].governor_approved_date) {
                        returnobj[values[row].id] = {
                            'governor_approved_date': "Required"
                        }
                        showAlert = true;

                    }
                }
            }
            if (showAlert) {
                alert("Governor Approved Date is required if Governor Approved is set to Yes.")
            }
            return returnobj;
        }
        return null;
    };

    sumbit = values => {
        const { authState } = this.props;
        const { info } = this.state;
        var objKeys = [];
        this.setState({is_loading: true});

        if (info === "Staff Lead Assignment") objKeys = ["staff_lead_id"];
        else if (info === "Selected for Presentation") objKeys = ["selected_presentation"];
        else if (info === "Recommended, Allocations, And Contingencies")
            objKeys = ["recommended_for_funding", "recommended_amount", "contingencies"];
        else if (info === "Research and Peer Review")
            objKeys = ["is_research_project", "peer_review", "peer_waived", "explanation_waiver", "lock_research_addendum"];
        else if (info === "Governor Action") objKeys = ["new_status", "governor_date"];
        else if (info === "Work Plan Approval") objKeys = ["workplan_approved", "workplan_date", "workplan_approval_notes", "consent"];
        else if (info === "Administering Agency") objKeys = ["through", "agency"];
        else if (info === "Fiscal Year") 
            objKeys = [
                "fiscal_year_a", "fiscal_year_a_amount", 
                "fiscal_year_b", "fiscal_year_b_amount",
                "fiscal_year_c", "fiscal_year_c_amount",
                "fiscal_year_d", "fiscal_year_d_amount",
                "fiscal_year_notes"
            ];
        else if (info === "Source of Funds") 
            objKeys = [
                "enrtf", "enrtf_amount",
                "frf", "frf_amount",
                "oocm", "oocm_amount",
                "glpa", "glpa_amount",
                "source_of_funds_notes"
            ];
        else if (info === "All Subject and Legacy Data") 
            objKeys = [
                "ag_forestry_mining", "biological_diversity", "ed_outreach",
                "historic_preservation", "invasive_species", "nat_areas_habitat",
                "nr_info_planning", "recreation", "renewable_energy",
                "water_resources", "admin", "link_to_subd", "enrtf_custom_id"
            ];
        else if (info === "Legal Citation and Appropriation Language")
            objKeys = [
                "name",
                "appropriation_amount",
                "special_session",
                "article",
                "app_end_date",
                "citation_ml_year",
                "chapter",
                "section",
                "subdivision",
                "app_lanuage",
                "additional_app_lanuage",
                "additional_citation",
                "legislative_notes"
            ];
        else if (info === "Legislative Action") objKeys = ["new_status", "legislative_date"];
        else if (info === "Legislative Amendments") objKeys = ["passed_legislature", "passed_governor", "amendment_notes", "governor_approved_date"];
        var data = [];

        const keys = Object.keys(values);
        keys.forEach(x => {
            var reqObj = {
                id: values[x].id,
                user_id: values[x].user_id,
                name: values[x].name,
                ml_year: values[x].ml_year
            };

            objKeys.forEach(z => {
                if (
                    [
                        "is_research_project",
                        "peer_review",
                        "peer_waived",
                        "recommended_for_funding",
                        "selected_presentation",
                        "lock_research_addendum",
                        "workplan_approved",
                        "consent",
                    ].includes(z)
                ) {
                    reqObj[z] = values[x][z] ? values[x][z] : false;
                } else if (
                    z === "workplan_date" ||
                    z === "legislative_date" ||
                    z === "governor_date" ||
                    z === "app_end_date" ||
                    z === "governor_approved_date"
                ) {
                    //dates remove time
                    reqObj[z] = values[x][z] ? values[x][z].substring(0, 10) : null;
                } else if (z === "passed_legislature" || z === "passed_governor") {
                    if (values[x][z] === "")
                        reqObj[z] = null;
                    else
                        reqObj[z] = values[x][z];
                } else {
                    reqObj[z] = values[x][z] ? values[x][z] : null;
                }
            });

            data.push(reqObj);
        });

        fetch("/batch/update", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Token " + authState.user.auth_token
            },
            body: JSON.stringify({
                data: data,
                key: objKeys
            })
        })
            .then(result => {
                if (!result.ok) {
                    return result.text().then(text => {
                        throw new Error(text);
                    });
                } else {
                    return result.json();
                }
            })
            .then(data => {
                // reload and then wait till synced to display success snack bar
                if (info === "Research and Peer Review") {
                    this.props.reloadListOfModels(["DraftWorkPlanView"]);
                }
                if (info === "Legislative Amendments") {
                    this.props.reloadListOfModels(["LegislativeAmendment"]);
                }
                if (info === "Legal Citation and Appropriation Language") {
                    this.props.reloadListOfModels(["DraftWorkPlanView"]);
                    this.props.reloadListOfModels(["FinalWorkPlanView"]);
                }

                this.props.reloadListOfModels(["ProposalView"]);
                this.setState({ loading: true });
                this.setState({is_loading: false});
            })
            .catch(e => {
                this.setState({ dont: true, snackbarOpen: true, isSuccess: false, is_loading: false });
                console.log("error", e);
            });
    };

    getDefaultValues = values => {
        const { info } = this.state;
        var defaultValues = {};
        var tempValues = [];

        values.forEach(x => {
            tempValues.push({ ...x });
        });

        tempValues.forEach(x => {
            if (info === "Work Plan Approval" && x["workplan_date"]) {
                x["workplan_date"] = x["workplan_date"] + "T12:00:00";
            }

            if (info === "Governor Action" && x["governor_date"]) {
                x["governor_date"] = x["governor_date"] + "T12:00:00";
            }

            if (info === "Legal Citation and Appropriation Language" && x["app_end_date"]) {
                x["app_end_date"] = x["app_end_date"] + "T12:00:00";
            }

            if (info === "Legislative Amendments") {
                if (x["date_submitted"]) {
                    x["date_submitted"] = x["date_submitted"] + "T12:00:00";
                }
                if (x["timeline"]) {
                    x["timeline"] = x["timeline"] + "T12:00:00";
                }
                if (x["governor_approved_date"]) {
                    x["governor_approved_date"] = x["governor_approved_date"] + "T12:00:00";
                }
            }

            if (info === "Legislative Action") {
                if (x["legislative_date"]) {
                    x["legislative_date"] = x["legislative_date"] + "T12:00:00";
                }

                if (legisLativeActionOptions.includes(x["secondary_status"])) {
                    x["new_status"] = x["secondary_status"];
                } else {
                    x["new_status"] = null;
                }
            }

            if (info === "Governor Action") {
                if (governerOptions.includes(x["secondary_status"])) {
                    x["new_status"] = x["secondary_status"];
                } else {
                    x["new_status"] = null;
                }
            }

            defaultValues[x.id.toString()] = x;
        });

        return defaultValues;
    };

    getColumnData = () => {
        const { info } = this.state;
        var selectData = [];
        var infoData = [];

        if (info === "Staff Lead Assignment") {
            selectData = staffData;
            infoData = commonColumnData;
        } else if (info === "Selected for Presentation") {
            selectData = selectedData;
            infoData = commonColumnDataNoOrgLeadOrSubd;
        } else if (info === "Recommended, Allocations, And Contingencies") {
            selectData = recommendedData;
            infoData = commonColumnDataNoOrgLeadOrSubd;
        } else if (info === "Research and Peer Review") {
            selectData = researchData;
            infoData = commonColumnDataWorkPlan2;
        } else if (info === "Governor Action") {
            selectData = governorData;
            infoData = commonColumnDataWorkPlan;
        } else if (info === "Work Plan Approval") {
            selectData = workPlanData;
            infoData = commonColumnDataWorkPlan;
        } else if (info === "Administering Agency") {
            selectData = adminsteringData;
            infoData = commonColumnDataWorkPlan;
        } else if (info === "Fiscal Year") {
            selectData = fiscalYearData;
            infoData = commonColumnDataWorkPlan;
        } else if (info === "Source of Funds") {
            selectData = sourceFundsData;
            infoData = commonColumnDataWorkPlan;
        } else if (info === "All Subject and Legacy Data") {
            selectData = legacyData;
            infoData = commonColumnDataWorkPlan;
        } else if (info === "Legal Citation and Appropriation Language") {
            selectData = legalData;
            infoData = commonColumnDataWorkPlan3;
        } else if (info === "Legislative Action") {
            selectData = legistLativeData;
            infoData = commonColumnDataWorkPlan2;
        } else if (info === "Legislative Amendments") {
            selectData = lAmendmentData;
            infoData = commonColumnDataWorkPlan;
        }

        return [...infoData, ...selectData];
    };

    resetAll = formApi => {
        const { info } = this.state;
        formApi.resetAll();

        // above reset doesn't work with combination of handlePeer method.
        // This resets default values and set values of the form to them.
        if (info === "Research and Peer Review") {
            this.setState({ dont: true });
        }
    };
    handlePeer = (e, id, formApi, key) => {
        // Peer Review Needed has to be checked if waived or completed is checked
        // If uncheck Peer Review uncheck others
        var value;
        if (e && formApi) {
            if (key === "needed") {
                value = formApi.getValue(id.toString());
                value.peer_review = e;
                formApi.setValue(id.toString(), value);
            } else if (key === "waived") {
                value = formApi.getValue(id.toString());
                value.lock_research_addendum = true;
                value.peer_review = true;
                value.peer_waived = e;
                formApi.setValue(id.toString(), value);
            } else {
                value = formApi.getValue(id.toString());
                value.lock_research_addendum = true;
                value.peer_review = true;
                formApi.setValue(id.toString(), value);
            }
        } else if (!e && formApi) {
            if (key === "needed") {
                value = formApi.getValue(id.toString());
                value.peer_review = e;
                value.peer_waived = false;
                value.lock_research_addendum = false;
                formApi.setValue(id.toString(), value);
            } else if (key === "waived") {
                value = formApi.getValue(id.toString());
                value.peer_waived = e;
                formApi.setValue(id.toString(), value);
            } else {
                value = formApi.getValue(id.toString());
                value.lock_research_addendum = e;
                formApi.setValue(id.toString(), value);
            }
        }
    };

    displayFunding = n => {
        if (n.appropriation_amount)
            return "$" + n.appropriation_amount.toLocaleString();
        if (n.recommended_amount)
            return "$" + n.recommended_amount.toLocaleString();
        return n.funding_total ? "$" + n.funding_total.toLocaleString() : "-";
    };

    displayDate = n => {
        if (!n) {
            return "";
        }
        const displayDate = new Date(n + "T12:00:00");
        const dateFns = new DateFnsUtils();
        return dateFns.format(displayDate, "MM/dd/yyyy");
    };

    displayDate2 = n => {
        if (!n) {
            return "";
        }
        const displayDate = new Date(n);
        const dateFns = new DateFnsUtils();
        return dateFns.format(displayDate, "MM/dd/yyyy");
    };

    render() {
        const { classes, proposals, workplans, staff, rfpYears, legislative_amendments } = this.props;
        const {
            activeRFP,
            dont,
            isProposal,
            isSuccess,
            info,
            order,
            orderBy,
            page,
            snackbarOpen,
            rowsPerPage, is_loading
        } = this.state;

        const years = rfpYears.map(x => ({
            label: x.year,
            value: x.year
        }));

        const tempStaff = [{ first_name: "", last_name: "", value: null }, ...staff];

        const staffChoices = tempStaff.map(x => ({
            label: x.first_name + " " + x.last_name,
            value: x.id
        }));

        var colData = this.getColumnData();

        const show = activeRFP && info;

        var filtered_proposals = [];
        if (isProposal) {
            filtered_proposals = proposals;
        } else {
            if (info === "Legal Citation and Appropriation Language") {
                //filtered_proposals = workplans;
                filtered_proposals = workplans.filter(
                    x =>
                        x.secondary_status === "Pending Action By The Legislature" ||
                        x.secondary_status === "Passed By Legislature" ||
                        x.secondary_status === "Adjusted And Passed By Legislature" ||
                        x.secondary_status === "Legislative Amendment Governor Approved" ||
                        x.secondary_status === "Recommended for Funding" || 
                        governerOptions.includes(x.secondary_status)
                );
            } else if (info === "Governor Action") {
                filtered_proposals = workplans.filter(
                    x =>
                        x.secondary_status === "Passed By Legislature" ||
                        x.secondary_status === "Adjusted And Passed By Legislature" ||
                        governerOptions.includes(x.secondary_status)
                );
            } else if (info === "Work Plan Approval") {
                filtered_proposals = workplans.filter(
                    x =>
                        x.secondary_status === "Governor Approved" ||
                        x.secondary_status === "Governor Approved As Adjusted"
                );
            } else if (info === "Legislative Amendments") {
                filtered_proposals = legislative_amendments;
            }  else {
                filtered_proposals = workplans;
            }
        }

        const GOVERNOR_OPTIONS = [{ value: null, label: " " }, ...governerOptions.map(x => ({ value: x, label: x }))];
        const LEGISLATIVE_OPTIONS = [
            { value: null, label: " " },
            ...legisLativeActionOptions.map(x => ({ value: x, label: x }))
        ];

        return (
            <AppContainer authenticated>
                <Grid container spacing={16}>
                    <PageHeader title="Administration - Batch Proposal Updates" admin_page />
                    <Grid item xs={12}>
                        <Form dontValidateOnMount={true} validateOnSubmit={true} getApi={el => (this.selectApi = el)}>
                            {formApi => (
                                <form>
                                    <Grid container spacing={16} style={{marginTop:-24,marginBottom:-24}}>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Select
                                                fullWidth
                                                field="rfp_year"
                                                label="RFP Year/M.L Year"
                                                options={years}
                                                eventHandle={val => {
                                                    this.handleYear(val);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}>
                                            <Select
                                                fullWidth
                                                field="info"
                                                label="Select Information to Batch Update"
                                                options={MAKE_OPTIONS(
                                                    activeRFP && !activeRFP.draft_created ? proposalOptions : options
                                                )}
                                                eventHandle={val => {
                                                    this.handleInfo(val);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} xl={4}></Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:-40}}>
                        {!dont && (
                            <Form
                                ref={this.Formref}
                                getApi={el => (this.formApi = el)}
                                validateError={this.errorValidator}
                                defaultValues={this.getDefaultValues(filtered_proposals)}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                onSubmit={(values, e, formApi) => this.sumbit(values, e, formApi)}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm} style={{ width: "100%" }}>
                                        <ExpansionPanel CollapseProps={{ unmountOnExit: true }} defaultExpanded={show} disabled={!show}>
                                            <ExpansionPanelSummary
                                                className={classes.expansionSummaryRoot}
                                                expandIcon={<ExpandMoreIcon />}>
                                                <Typography variant="h3" style={{ width: "100%" }}>
                                                    <ListAltIcon className={classes.expansionIcon} />
                                                    {info ? info : "Select Information to Batch Update"}
                                                </Typography>
                                                {is_loading && (
                                                    <div style={{marginBottom:10}}>
                                                        <CircularProgress 
                                                            style={{ marginTop:10 }} 
                                                            size={40} aria-label="Loading Icon" thickness={1.5} 
                                                        />
                                                    </div>
                                                )}
                                                {!is_loading && (
                                                    <>
                                                    <Button
                                                        disabled={!show}
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ width: 170, marginRight: 20, marginBottom: 20 }}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                        }}>
                                                        Save
                                                    </Button>
                                                    <Button
                                                        disabled={!show}
                                                        variant="contained"
                                                        color="default"
                                                        style={{ width: 170, marginRight: "20%", marginBottom: 20 }}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            this.resetAll(formApi);
                                                        }}>
                                                        &nbsp;&nbsp;&nbsp;Cancel
                                                    </Button>
                                                    </>
                                                )}
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails style={{padding:0}}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{overflowX:"auto", minHeight: 450, height: "calc(100vh - 450px)"}}>
                                                        <Table className={classes.table}>
                                                            <EnhancedTableHead
                                                                columnData={colData}
                                                                order={order}
                                                                stickyHeader
                                                                orderBy={orderBy}
                                                                onRequestSort={this.handleRequestSort}
                                                            />
                                                            <TableBody>
                                                                {filtered_proposals
                                                                    .slice(
                                                                        page * rowsPerPage,
                                                                        page * rowsPerPage + rowsPerPage
                                                                    )
                                                                    .map((n, index) => {
                                                                        return (
                                                                            <TableRow
                                                                                key={n.proposal_unique_id + "term"}>
                                                                                <CustomTableCell
                                                                                    component="th"
                                                                                    id={n.proposal_unique_id}>
                                                                                    {n.proposal_unique_id}
                                                                                </CustomTableCell>
                                                                                {!isProposal && (
                                                                                    <CustomTableCell>
                                                                                        {n.citation_ml_year &&
                                                                                            n.citation_ml_year}
                                                                                        {n.citation_ml_year &&
                                                                                            n.subdivision &&
                                                                                            "-"}
                                                                                        {n.subdivision &&
                                                                                            n.subdivision}
                                                                                    </CustomTableCell>
                                                                                )}
                                                                                {info === "Legal Citation and Appropriation Language" ? (
                                                                                    <CustomTableCell>
                                                                                        <TextField
                                                                                            aLabel={`${n.proposal_unique_id} name`}
                                                                                            className={
                                                                                                classes.cellWidth_50
                                                                                            }
                                                                                            field={`${n.id}.name`}
                                                                                        />
                                                                                    </CustomTableCell>
                                                                                ) : (
                                                                                    <CustomTableCell
                                                                                        className={classes.cellWidth_2}>
                                                                                        {n.name}
                                                                                    </CustomTableCell>
                                                                                )}
                                                                                <CustomTableCell
                                                                                    className={classes.cellWidth_2}>
                                                                                    {n.organization}
                                                                                    {n.department
                                                                                        ? " / " + n.department
                                                                                        : ""}
                                                                                </CustomTableCell>
                                                                                {info ===
                                                                                    "Staff Lead Assignment" && (
                                                                                    <CustomTableCell>
                                                                                        {n.org_staff_name}
                                                                                    </CustomTableCell>
                                                                                )}
                                                                                <CustomTableCell>
                                                                                    {n.first_name
                                                                                        ? n.first_name
                                                                                        : ""}{" "}
                                                                                    {n.last_name ? n.last_name : ""}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell
                                                                                    className={classes.cellWidth_2}>
                                                                                    {n.category}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell>
                                                                                    {n.pm_first_name}{" "}
                                                                                    {n.pm_last_name}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell>
                                                                                {(info === "Staff Lead Assignment" || info === "Research and Peer Review" || info === "Legislative Action")  && (
                                                                                    this.displayFunding(n)
                                                                                )}
                                                                                {(info === "Selected for Presentation" || info === "Recommended, Allocations, And Contingencies")  && (
                                                                                    n.funding_total ? "$" + n.funding_total.toLocaleString() : "-"
                                                                                )}
                                                                                {(info === "Legal Citation and Appropriation Language")  && (
                                                                                    n.recommended_amount ? "$" + n.recommended_amount.toLocaleString() : "-"
                                                                                )}
                                                                                {(info === "Governor Action" || info === "Administering Agency" || info === "Fiscal Year" || info === "Source of Funds" || info === "All Subject and Legacy Data" || info === "Work Plan Approval" || info === "Legislative Amendments")  && (
                                                                                    n.appropriation_amount ? "$" + n.appropriation_amount.toLocaleString() : "-"
                                                                                )}
                                                                                </CustomTableCell>
                                                                                <CustomTableCell>
                                                                                    {n.status}
                                                                                </CustomTableCell>
                                                                                {info ===
                                                                                    "Staff Lead Assignment" && (
                                                                                    <CustomTableCell>
                                                                                        <Select
                                                                                            label=" "
                                                                                            aLabel={`${n.proposal_unique_id} stafflead`}
                                                                                            className={
                                                                                                classes.tableSelect
                                                                                            }
                                                                                            field={`${n.id}.staff_lead_id`}
                                                                                            options={staffChoices}
                                                                                        />
                                                                                    </CustomTableCell>
                                                                                )}
                                                                                {info ===
                                                                                    "Selected for Presentation" && (
                                                                                    <CustomTableCell>
                                                                                        <Checkbox
                                                                                            aLabel={`${n.proposal_unique_id} selectedforpresentation`}
                                                                                            field={`${n.id}.selected_presentation`}
                                                                                        />
                                                                                    </CustomTableCell>
                                                                                )}
                                                                                {info ===
                                                                                    "Recommended, Allocations, And Contingencies" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                disabled
                                                                                                aLabel={`${n.proposal_unique_id} selectedforpresentation`}
                                                                                                field={`${n.id}.selected_presentation`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                aLabel={`${n.proposal_unique_id} recommendedforfunding`}
                                                                                                field={`${n.id}.recommended_for_funding`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} recommendedamount`}
                                                                                                field={`${n.id}.recommended_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                className={
                                                                                                    classes.contingencies
                                                                                                }
                                                                                                multiline
                                                                                                aLabel={`${n.proposal_unique_id} contingencies`}
                                                                                                field={`${n.id}.contingencies`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info ===
                                                                                    "Research and Peer Review" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            {n.include_research &&
                                                                                            n.include_research ===
                                                                                                true
                                                                                                ? "Yes"
                                                                                                : "No"}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                aLabel={`${n.proposal_unique_id} isresearch`}
                                                                                                field={`${n.id}.is_research_project`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                eventHandle={e =>
                                                                                                    this.handlePeer(
                                                                                                        e,
                                                                                                        n.id,
                                                                                                        formApi,
                                                                                                        "needed"
                                                                                                    )
                                                                                                }
                                                                                                aLabel={`${n.proposal_unique_id} peerreviewneeded`}
                                                                                                field={`${n.id}.peer_review`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                eventHandle={e =>
                                                                                                    this.handlePeer(
                                                                                                        e,
                                                                                                        n.id,
                                                                                                        formApi,
                                                                                                        "waived"
                                                                                                    )
                                                                                                }
                                                                                                aLabel={`${n.proposal_unique_id} peerreviewwaived`}
                                                                                                field={`${n.id}.peer_waived`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} explanationwaiver`}
                                                                                                multiline
                                                                                                field={`${n.id}.explanation_waiver`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                eventHandle={e =>
                                                                                                    this.handlePeer(
                                                                                                        e,
                                                                                                        n.id,
                                                                                                        formApi,
                                                                                                        "locked"
                                                                                                    )
                                                                                                }
                                                                                                disabled={
                                                                                                    formApi.values[
                                                                                                        n.id
                                                                                                    ]
                                                                                                        ? formApi
                                                                                                                .values[
                                                                                                                n.id
                                                                                                            ]
                                                                                                                .peer_waived
                                                                                                        : false
                                                                                                }
                                                                                                aLabel={`${n.proposal_unique_id} peerreviewcompleted`}
                                                                                                field={`${n.id}.lock_research_addendum`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info === "Governor Action" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            {n.secondary_status}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Select
                                                                                                label=" "
                                                                                                aLabel={`${n.proposal_unique_id} governoraction`}
                                                                                                className={
                                                                                                    classes.tableSelect1
                                                                                                }
                                                                                                options={
                                                                                                    GOVERNOR_OPTIONS
                                                                                                }
                                                                                                field={`${n.id}.new_status`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            {this.displayDate(
                                                                                                n.governor_date
                                                                                            )}
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info === "Work Plan Approval" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            {n.secondary_status}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                aLabel={`${n.proposal_unique_id} workplanapproved`}
                                                                                                field={`${n.id}.workplan_approved`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_2
                                                                                            }>
                                                                                            <DatePicker
                                                                                                aLabel={`${n.proposal_unique_id} dateofworkplanapproval`}
                                                                                                field={`${n.id}.workplan_date`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                aLabel={`${n.proposal_unique_id} consent`}
                                                                                                field={`${n.id}.consent`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_35
                                                                                            }>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} workplanapprovalnotes`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                fullWidth
                                                                                                multiline
                                                                                                field={`${n.id}.workplan_approval_notes`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info ===
                                                                                    "Administering Agency" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            {n.secondary_status}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Select
                                                                                                label=" "
                                                                                                aLabel={`${n.proposal_unique_id} through`}
                                                                                                className={
                                                                                                    classes.tableSelect
                                                                                                }
                                                                                                field={`${n.id}.through`}
                                                                                                options={[
                                                                                                    {
                                                                                                        value: null,
                                                                                                        label: " "
                                                                                                    },
                                                                                                    {
                                                                                                        value:
                                                                                                            "Direct",
                                                                                                        label:
                                                                                                            "Direct"
                                                                                                    },
                                                                                                    {
                                                                                                        value:
                                                                                                            "Pass Through",
                                                                                                        label:
                                                                                                            "Pass Through"
                                                                                                    }
                                                                                                ]}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} agency`}
                                                                                                multiline
                                                                                                field={`${n.id}.agency`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info === "Fiscal Year" && (
                                                                                    <>
                                                                                        <CustomTableCell className={classes.cellWidth_1}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_a`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_a_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_1}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_b`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_b_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_1}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_c`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_c_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_1}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_d`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.fiscal_year_d_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_35}>
                                                                                            <TextField
                                                                                                multiline
                                                                                                fullWidth
                                                                                                field={`${n.id}.fiscal_year_notes`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                    {info === "Source of Funds" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.enrtf`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.enrtf_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.frf`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.frf_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.oocm`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.oocm_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.glpa`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_115}>
                                                                                            <TextField
                                                                                                field={`${n.id}.glpa_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        
                                                                                        <CustomTableCell className={classes.cellWidth_35}>
                                                                                            <TextField
                                                                                                multiline
                                                                                                fullWidth
                                                                                                field={`${n.id}.source_of_funds_notes`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info === "All Subject and Legacy Data" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.ag_forestry_mining`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.biological_diversity`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.ed_outreach`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.historic_preservation`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.invasive_species`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.nat_areas_habitat`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.nr_info_planning`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.recreation`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.renewable_energy`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.water_resources`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <Checkbox
                                                                                                field={`${n.id}.admin`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_35}>
                                                                                            <TextField
                                                                                                fullWidth
                                                                                                field={`${n.id}.link_to_subd`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_2}>
                                                                                            <TextField
                                                                                                field={`${n.id}.enrtf_custom_id`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info ===
                                                                                    "Legal Citation and Appropriation Language" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            {n.secondary_status}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_2
                                                                                            }>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} appropriationamount`}
                                                                                                field={`${n.id}.appropriation_amount`}
                                                                                                useTextFormat
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_2
                                                                                            }>
                                                                                            <DatePicker
                                                                                                aLabel={`${n.proposal_unique_id} appenddate`}
                                                                                                field={`${n.id}.app_end_date`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} m.l_year`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                field={`${n.id}.citation_ml_year`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_2
                                                                                            }>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} specialsession`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                field={`${n.id}.special_session`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} chapter`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                field={`${n.id}.chapter`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} article`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                field={`${n.id}.article`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} section`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                field={`${n.id}.section`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} subdivion`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                field={`${n.id}.subdivision`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_35
                                                                                            }>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} additional_c`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                fullWidth
                                                                                                multiline
                                                                                                field={`${n.id}.additional_citation`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_35
                                                                                            }>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} applanguage`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                fullWidth
                                                                                                multiline
                                                                                                field={`${n.id}.app_lanuage`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_35
                                                                                            }>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} additional_a`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                fullWidth
                                                                                                multiline
                                                                                                field={`${n.id}.additional_app_lanuage`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_35
                                                                                            }>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} notes`}
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                                fullWidth
                                                                                                multiline
                                                                                                field={`${n.id}.legislative_notes`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info === "Legislative Action" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            <Select
                                                                                                label=" "
                                                                                                className={
                                                                                                    classes.tableSelect1
                                                                                                }
                                                                                                aLabel={`${n.proposal_unique_id} legislativeaction`}
                                                                                                options={
                                                                                                    LEGISLATIVE_OPTIONS
                                                                                                }
                                                                                                multiline
                                                                                                field={`${n.id}.new_status`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            {this.displayDate(
                                                                                                n.legislative_date
                                                                                            )}
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                                {info === "Legislative Amendments" && (
                                                                                    <>
                                                                                        <CustomTableCell>
                                                                                            {this.displayDate2(
                                                                                                n.date_submitted
                                                                                            )}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_35}>
                                                                                            {n.why}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell>
                                                                                            {this.displayDate2(
                                                                                                n.timeline
                                                                                            )}
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_2}>
                                                                                            <RadioGroup
                                                                                                style={{marginTop:-16}}
                                                                                                field={`${n.id}.passed_legislature`}
                                                                                                aLabel={`${n.proposal_unique_id} passed_legislature`}
                                                                                                fullWidth
                                                                                                options={[
                                                                                                    { label: "Yes", value: "true" },
                                                                                                    { label: "No", value: "false" }
                                                                                                ]}
                                                                                                alignment={true} />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_2}>
                                                                                            <RadioGroup
                                                                                                style={{marginTop:-16}}
                                                                                                field={`${n.id}.passed_governor`}
                                                                                                aLabel={`${n.proposal_unique_id} passed_governor`}
                                                                                                fullWidth
                                                                                                options={[
                                                                                                    { label: "Yes", value: "true" },
                                                                                                    { label: "No", value: "false" }
                                                                                                ]}
                                                                                                alignment={true} />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell
                                                                                            className={
                                                                                                classes.cellWidth_2
                                                                                            }>
                                                                                            <DatePicker
                                                                                                aLabel={`${n.proposal_unique_id} governor_approved_date`}
                                                                                                field={`${n.id}.governor_approved_date`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                        <CustomTableCell className={classes.cellWidth_35}>
                                                                                            <TextField
                                                                                                aLabel={`${n.proposal_unique_id} amendment_notes`}
                                                                                                fullWidth
                                                                                                multiline
                                                                                                field={`${n.id}.amendment_notes`}
                                                                                            />
                                                                                        </CustomTableCell>
                                                                                    </>
                                                                                )}
                                                                            </TableRow>
                                                                        );
                                                                    })}
                                                                {filtered_proposals.length < 1 && (
                                                                    <TableRow>
                                                                        <CustomTableCell
                                                                            colSpan={colData.length}
                                                                            className={classes.centerAlign}>
                                                                            <caption style={{display:"inline"}}>{info === "Legislative Amendments" ? "No Amendments Found" : "No Proposals Found"}</caption>
                                                                        </CustomTableCell>
                                                                    </TableRow>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                    {filtered_proposals.length > 25 && (
                                                        <Grid item xs={12}>
                                                            <Table>
                                                                <TableFooter>
                                                                    <TableRow>
                                                                        <TablePagination
                                                                            colSpan={colData.length}
                                                                            count={filtered_proposals.length}
                                                                            rowsPerPage={rowsPerPage}
                                                                            page={page}
                                                                            onChangePage={this.handleChangePage}
                                                                            onChangeRowsPerPage={
                                                                                this.handleChangeRowsPerPage
                                                                            }
                                                                            ActionsComponent={
                                                                                TablePaginationActionsWrapped
                                                                            }
                                                                        />
                                                                    </TableRow>
                                                                </TableFooter>
                                                            </Table>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </form>
                                )}
                            </Form>
                        )}
                    </Grid>
                </Grid>
                <Snackbar
                    handleSnackbarClose={this.handleSnackbarClose}
                    isSuccess={isSuccess}
                    snackbarOpen={snackbarOpen}
                    message={isSuccess ? "Batch Information Has Been Saved" : "Save Failed!"}
                />
            </AppContainer>
        );
    }
}

BatchProposal = connect(
    (state, ownProps) => ({
        authState: state.auth,
        syncState: state.sync,
        proposals: getProposals(state, ownProps),
        workplans: getWorkPlans(state, ownProps),
        legislative_amendments: getLegislativeAmendments(state, ownProps),
        staff: getStaffUsers(state),
        rfpYears: getRFPYears(state)
    }),
    {
        reloadListOfModels: reloadListOfModels
    }
)(BatchProposal);

export default withStyles(styles)(withRouter(BatchProposal));
